import {ServicePointLocatorStoryblok} from 'types/storyblok-types'
import {StoryblokComponent} from '@storyblok/react'
import HeaderContainer from 'components/Content/Header'
import DownloadAppButtonBlockContainer from 'components/shared/DownloadAppButtonBlockContainer'
import ServicePointLocator from './ServicePointLocator'

type ServicePointLocatorPageProps = {
  blok: ServicePointLocatorStoryblok
  publishedAt: string
}

const ServicePointLocatorPage = ({blok}: ServicePointLocatorPageProps) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="mx-[20px] flex flex-col items-start justify-between pb-8 pt-[25px] lg:mx-[100px] lg:flex-row lg:items-end lg:pt-[50px]">
          <div className="w-full lg:w-1/2 xl:w-1/3">
            {blok.header ? <HeaderContainer blok={blok.header[0]} /> : null}
          </div>
          {blok.download_button ? (
            <DownloadAppButtonBlockContainer blok={blok.download_button[0]} />
          ) : null}
        </div>

        {blok.content
          ? blok.content.map((nestedBlok: any) => {
              if (nestedBlok.component === 'data_block') {
                return <ServicePointLocator key="sdpl" blok={blok} />
              } else {
                return (
                  <div
                    className="mt-16 px-5 lg:mx-[100px]"
                    key={nestedBlok._uid}
                  >
                    <StoryblokComponent blok={nestedBlok} />
                  </div>
                )
              }
            })
          : null}
      </div>
    </>
  )
}

export default ServicePointLocatorPage
